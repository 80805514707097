'use client';

// imports
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { userProfile } from '@/lib/atoms/profile';

// lib files
import { handleRecommendedShowClick } from '@/lib/content-services/rec-engine';
import safeFetch from '@/lib/helpers/safe-fetch';
import { ShowRowContent } from "@/lib/types/api/show-data";
import { ProfileData } from '@/lib/types/api/profile-data';

// components
import ShowRow from "@/components/ShowRow/ShowRow";
interface MustWatchOrRecommendedDramasRowProps {
  profileStub?: ProfileData;
  depRecommendedDramas?: ShowRowContent[];
  mustWatchDramas: ShowRowContent[];
}
export const MustWatchOrRecommendedDramasRow = ({
  mustWatchDramas
}: MustWatchOrRecommendedDramasRowProps) => {
  const [profile] = useAtom(userProfile);
  const [recommendedDramas, setRecommendedDramas] = useState<ShowRowContent[] | null>(null);
  useEffect(() => {
    if (recommendedDramas) return;
    const fetchRecommended = async () => {
      try {
        const response = await safeFetch('/api/profile/recommended-dramas/');
        const json = await response.json();
        const recommendedDramasData = json.recommendedShowsData;
        setRecommendedDramas(recommendedDramasData);
      } catch (error) {
        console.error(error);
      }
    };
    if (profile) {
      fetchRecommended();
    }
  }, [profile, recommendedDramas]);

  // if the user is logged in and the recommendedDramas are available, show them
  if (profile && recommendedDramas && recommendedDramas.length > 0) {
    return <ShowRow shows={recommendedDramas} onShowPosterClick={handleRecommendedShowClick} title="Recommended Dramas" />;
  }
  // otherwise, show the must watch dramas
  else if (mustWatchDramas && mustWatchDramas.length > 0) {
    return <ShowRow shows={mustWatchDramas} title="Must Watch Dramas" />;
  }
  return null;
};